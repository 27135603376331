import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Input } from "antd";
import EditIngredientsForm from "./EditIngredientsForm";
import DrinkNameForm from "./DrinkNameForm";
import DescriptionForm from "./DescriptionForm";
import InstructionsForm from "./InstructionsForm";
import DrinkCategoryDropdownForm from "./DrinkCategoryDropdownForm";
import GlassDropdownForm from "./GlassDropdownForm";
import AlcoholicDrinkToggle from "./AlcoholicDrinkToggle";

import IngredientsQuery from "../queries/IngredientsQuery";
import GlassesQuery from "../queries/GlassesQuery";
import DrinkCategoriesQuery from "../queries/DrinkCategoriesQuery";
import UnitsQuery from "../queries/UnitsQuery";

const EditDrinkForm = ({ drink }) => {
  // key={drink.id}
  // id={drink.id}
  // name={drink.name}
  // createdByUserId={drink.createdByUserId}
  // drinkCategoryId={drink.drinkCategoryId}
  // glassId={drink.glassId}
  // alcoholic={drink.alcoholic}
  // description={drink.description}
  // drinkIngredients={drink.drinkIngredients}
  // instructions={drink.instructions}
  const navigate = useNavigate();
  const useFetchData = (QueryClass) => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const queryInstance = new QueryClass();
      queryInstance
        .getData(0, 5000)
        .then((data) => setData(data))
        .catch((error) => console.error(error));
    }, [QueryClass]);

    return data;
  };

  const submitForm = async (values) => {
    try {
      const response = await fetch(
        `http://localhost:8080/api/drinks/${drink.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
          },
          body: JSON.stringify(values),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        navigate("/drinksPageEditFailure", { state: { data: data } });
      } else {
        console.log("Drink edited successfully:", data);
        // Redirect to the drink edited successfully page
        navigate(`/drinks/${drink.id}`, { state: { data: data } });
      }
    } catch (error) {
      console.error("Error editing drink:", error);
      navigate("/drinksPageEditFailure", { state: { error: error.message } });
    }
  };

  // Load the data for the dropdowns
  const ingredientsList = useFetchData(IngredientsQuery);
  const glassesList = useFetchData(GlassesQuery);
  const drinkCategoriesList = useFetchData(DrinkCategoriesQuery);
  const unitsList = useFetchData(UnitsQuery);

  const onFinish = (values) => {
    console.log("Success:", values);
    // TODO: Here you would typically send the form values to your server
    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <h1>Edit Drink</h1>
      <p>This is the edit drink page.</p>
      <Form
        name="basic"
        initialValues={{
          remember: true,
          id: drink.id,
          name: drink.name,
          description: drink.description,
          createdByUserId: drink.createdByUserId,
          alcoholic: drink.alcoholic,
          drinkCategoryId: drink.drinkCategoryId,
          glassId: drink.glassId,
          drinkIngredients: drink.drinkIngredients,
          instructions: drink.instructions,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name={["id"]} hidden>
          <Input />
        </Form.Item>
        <DrinkNameForm />
        <Form.Item name={["createdByUserId"]} hidden>
          <Input />
        </Form.Item>
        <DescriptionForm />
        <InstructionsForm />
        <AlcoholicDrinkToggle />
        <DrinkCategoryDropdownForm drinkCategoriesList={drinkCategoriesList} />
        <GlassDropdownForm glassesList={glassesList} />

        <EditIngredientsForm
          ingredientsList={ingredientsList}
          unitsList={unitsList}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditDrinkForm;
