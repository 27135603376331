class UnitsQuery {
  constructor() {
    this.token = sessionStorage.getItem("jwt");
    this.baseURL = "http://localhost:8080/api";
  }

  async getData() {
    try {
      const response = await fetch(`${this.baseURL}/units?page=0&size=5000`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching units:", error);
      throw error;
    }
  }
}

export default UnitsQuery;
