import { UserOutlined } from "@ant-design/icons";
import { Select, Form } from "antd";
import React from "react";

const { Option } = Select;

const GlassDropdownForm = ({ value, onChange, glassesList }) => {
  let items = [];
  if (glassesList && glassesList.content) {
    items = glassesList.content.map((item) => ({
      label: item.name,
      key: item.id,
      icon: <UserOutlined />,
    }));
  }

  const handleGlassChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Form.Item
      label="Glass"
      name="glassId"
      rules={[{ required: true, message: "Please select a glass!" }]}
    >
      <Select
        showSearch
        value={value}
        onChange={handleGlassChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {items.map((item) => (
          <Option key={item.key} value={item.key}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default GlassDropdownForm;
