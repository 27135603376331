import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ApplicationHeader from "./components/ApplicationHeader";
import LoginBar from "./components/LoginBar";
import NavigationBar from "./components/NavigationBar";
import ApplicationFooter from "./components/ApplicationFooter";

import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import DrinksPage from "./pages/DrinksPage";
import DrinkDetailsPage from "./pages/DrinkDetailsPage.js";
import DrinkDetailsEditPage from "./pages/DrinkDetailsEditPage.js";
import DrinkDetailsDeletePage from "./pages/DrinkDetailsDeletePage.js";
import IngredientsPage from "./pages/IngredientsPage";
import DrinksPageCreate from "./pages/DrinksPageCreate";
import DrinksPageCreateSuccess from "./pages/DrinksPageCreateSuccess";
import DrinksPageCreateFailure from "./pages/DrinksPageCreateFailure";
import DrinksPageEditFailure from "./pages/DrinksPageEditFailure.js";
import GlassesPage from "./pages/GlassesPage";
import LoginPage from "./pages/LoginPage";
import LoginPageSuccess from "./pages/LoginPageSuccess";
import LoginPageFailure from "./pages/LoginPageFailure";
import LogoutPage from "./pages/LogoutPage";
import UnitsPage from "./pages/UnitsPage";
import NotFoundPage from "./pages/NotFoundPage";
import AuthContext from "./context/AuthContext.js";

function AppRouter() {
  const [jwt, setJwt] = useState(sessionStorage.getItem("jwt"));
  return (
    <div
      style={{
        minWidth: "600px",
        maxWidth: "1000px",
        margin: "0 auto",
        backgroundColor: "White",
      }}
    >
      <BrowserRouter>
        <ApplicationHeader />
        <AuthContext.Provider value={{ jwt, setJwt }}>
          <LoginBar />

          <NavigationBar />
          <div className="ApplicationPage">
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/drinks" element={<DrinksPage />} />
              <Route path="/drinks/:id" element={<DrinkDetailsPage />} />
              <Route
                path="/drinks/edit/:id"
                element={<DrinkDetailsEditPage />}
              />
              <Route
                path="/drinks/delete/:id"
                element={<DrinkDetailsDeletePage />}
              />
              <Route path="/drinks/create" element={<DrinksPageCreate />} />
              <Route
                path="/drinksPageCreateSuccess"
                element={<DrinksPageCreateSuccess />}
              />
              <Route
                path="/drinksPageCreateFailure"
                element={<DrinksPageCreateFailure />}
              />
              <Route
                path="/drinksPageEditFailure"
                element={<DrinksPageEditFailure />}
              />
              <Route path="/ingredients" element={<IngredientsPage />} />
              <Route path="/glasses" element={<GlassesPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/loginSuccess" element={<LoginPageSuccess />} />
              <Route path="/loginFailure" element={<LoginPageFailure />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/units" element={<UnitsPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </AuthContext.Provider>
      </BrowserRouter>
      <ApplicationFooter />
    </div>
  );
}

export default AppRouter;
