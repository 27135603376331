import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Input } from "antd";
import AddIngredientsForm from "./AddIngredientsForm";
import DrinkNameForm from "./DrinkNameForm";
import DescriptionForm from "./DescriptionForm";
import InstructionsForm from "./InstructionsForm";
import DrinkCategoryDropdownForm from "./DrinkCategoryDropdownForm";
import GlassDropdownForm from "./GlassDropdownForm";
import AlcoholicDrinkToggle from "./AlcoholicDrinkToggle";

import IngredientsQuery from "../queries/IngredientsQuery";
import GlassesQuery from "../queries/GlassesQuery";
import DrinkCategoriesQuery from "../queries/DrinkCategoriesQuery";
import UnitsQuery from "../queries/UnitsQuery";

const CreateDrinkForm = () => {
  const navigate = useNavigate();
  const useFetchData = (QueryClass) => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const queryInstance = new QueryClass();
      queryInstance
        .getData(0, 5000)
        .then((data) => setData(data))
        .catch((error) => console.error(error));
    }, [QueryClass]);

    return data;
  };

  const submitForm = async (values) => {
    try {
      const response = await fetch("http://localhost:8080/api/drinks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Drink created successfully:", data);

      // Redirect to the drink created successfully page
      navigate("/drinksPageCreateSuccess", { state: { data: data } });
    } catch (error) {
      console.error("Error creating drink:", error);
      const data = JSON.stringify(values);
      data.error = error.message;
      navigate("/drinksPageCreateFailure", { state: { data: data } });
    }
  };

  // Load the data for the dropdowns
  const ingredientsList = useFetchData(IngredientsQuery);
  const glassesList = useFetchData(GlassesQuery);
  const drinkCategoriesList = useFetchData(DrinkCategoriesQuery);
  const unitsList = useFetchData(UnitsQuery);

  const onFinish = (values) => {
    console.log("Success:", values);
    // TODO: Here you would typically send the form values to your server
    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <h1>New Drink</h1>
      <p>This is the create drink page.</p>
      <Form
        name="basic"
        initialValues={{
          drinkCategoryId: 4, // Default to "Cocktail" drink category
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <DrinkNameForm />
        <Form.Item
          name={["createdByUserId"]}
          initialValue={sessionStorage.getItem("userId")}
          hidden
        >
          <Input />
        </Form.Item>
        <DescriptionForm />
        <InstructionsForm />
        <AlcoholicDrinkToggle />
        <DrinkCategoryDropdownForm drinkCategoriesList={drinkCategoriesList} />
        <GlassDropdownForm glassesList={glassesList} />

        <AddIngredientsForm
          ingredientsList={ingredientsList}
          unitsList={unitsList}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateDrinkForm;
