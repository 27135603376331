import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import IngredientsQuery from "../queries/IngredientsQuery";
import Ingredient from "../entities/Ingredient";

function IngredientsResultsPaginated() {
  const [ingredientsList, setIngredientsList] = useState({ content: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const queryInstance = new IngredientsQuery();
    queryInstance
      .getData(currentPage - 1, pageSize)
      .then((data) => setIngredientsList(data))
      .catch((error) => console.error(error));
  }, [currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={ingredientsList.totalElements}
        onChange={handlePageChange}
      />
      <div className="leftPadding20">
        {!ingredientsList.content.length ? (
          <h1>No Drink Ingredients Found</h1>
        ) : (
          ingredientsList.content.map((ingredient) => (
            <Ingredient
              key={ingredient.id}
              id={ingredient.id}
              name={ingredient.name}
              ingredientNameSingular={ingredient.ingredientNameSingular}
              ingredientNamePlural={ingredient.ingredientNamePlural}
              ingredientCategoryId={ingredient.ingredientCategoryId}
              description={ingredient.description}
              ingredientSubcategoryId={ingredient.ingredientSubcategoryId}
            />
          ))
        )}
      </div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={ingredientsList.totalElements}
        onChange={handlePageChange}
      />
    </div>
  );
}

export default IngredientsResultsPaginated;
