import { Form, Input, Button } from "antd";

const LoginForm = ({ form, handleLogin }) => (
  <Form form={form} onFinish={handleLogin}>
    <Form.Item
      name="email"
      rules={[{ required: true, message: "Please input your email!" }]}
    >
      <Input placeholder="email" />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[{ required: true, message: "Please input your password!" }]}
    >
      <Input.Password placeholder="password" />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Log in
      </Button>
    </Form.Item>
  </Form>
);

export default LoginForm;
