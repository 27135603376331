import { UserOutlined } from "@ant-design/icons";
import { Select, Form } from "antd";
import React from "react";

const { Option } = Select;

const DrinkCategoryDropdownForm = ({
  value,
  onChange,
  drinkCategoriesList,
}) => {
  let items = [];
  if (drinkCategoriesList && drinkCategoriesList.content) {
    items = drinkCategoriesList.content.map((item) => ({
      label: item.name,
      key: item.id,
      icon: <UserOutlined />,
    }));
  }

  const handleDrinkCategoryChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Form.Item
      label="Drink Category"
      name="drinkCategoryId"
      rules={[{ required: true, message: "Please select a drink category!" }]}
    >
      <Select
        showSearch
        value={value}
        onChange={handleDrinkCategoryChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {items.map((item) => (
          <Option key={item.key} value={item.key}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DrinkCategoryDropdownForm;
