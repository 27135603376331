import Unit from "../entities/Unit";

const UnitsResults = ({ unitsList }) => {
  return (
    <div className="leftPadding20">
      {!unitsList.length ? (
        <h1>No Units Found</h1>
      ) : (
        unitsList.map((unit) => (
          <Unit
            key={unit.id}
            id={unit.id}
            name={unit.name}
            unitCategoryId={unit.unitCategoryId}
            description={unit.description}
          />
        ))
      )}
    </div>
  );
};

export default UnitsResults;
