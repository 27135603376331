import React from "react";
import { Form, Input } from "antd";

function DrinkNameForm() {
  return (
    <Form.Item
      label="Drink Name"
      name="name"
      rules={[{ required: true, message: "Please input the drink name!" }]}
    >
      <Input />
    </Form.Item>
  );
}

export default DrinkNameForm;
