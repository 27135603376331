import React from "react";
import { Form, Input } from "antd";

function InstructionsForm() {
  return (
    <Form.Item
      label="Instructions"
      name="instructions"
      rules={[{ required: true, message: "Please input drink instructions!" }]}
    >
      <Input />
    </Form.Item>
  );
}

export default InstructionsForm;
