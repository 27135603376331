import { Form } from "antd";
import LoginForm from "../forms/LoginForm";
import { useRef, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const LoginComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loginMessage = location.state?.message;
  const headerRef = useRef(null);
  const [authMessage, setAuthMessage] = useState("");
  const [form] = Form.useForm();
  const { setJwt } = useContext(AuthContext);

  const handleLogin = async (values) => {
    try {
      const res = await fetch(`http://localhost:8080/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });

      if (res.ok) {
        const data = await res.json();
        sessionStorage.setItem("jwt", data.token);
        sessionStorage.setItem("expiresIn", data.expiresIn);
        setJwt(data.token);
        fetchUser();
        setAuthMessage("");
      } else {
        console.log("Failed to log in.");
        setAuthMessage("Failed to log in.");
      }
    } catch (error) {
      console.error("Failed to fetch:", error);
    }
  };

  const fetchUser = async () => {
    const res = await fetch("http://localhost:8080/users/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    });

    if (res.ok) {
      const data = await res.json();
      sessionStorage.setItem("userFullName", data.fullName);
      sessionStorage.setItem("userId", data.id);
      sessionStorage.setItem("userRole", data.role.name);
      sessionStorage.setItem("userEmail", data.email);
      console.log("User data fetched successfully: ", data);
      navigate("/loginSuccess");
    } else {
      console.error("Failed to fetch user");
    }
  };

  return (
    <div ref={headerRef}>
      {loginMessage && <h2>{loginMessage}</h2>}
      {authMessage && <h2>{authMessage}</h2>}
      <div>
        <LoginForm form={form} handleLogin={handleLogin} />
      </div>
    </div>
  );
};

export default LoginComponent;
