import React from "react";
import { Row, Col, Form, Switch } from "antd";

function AlcoholicDrinkToggle() {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={6}>
        <Form.Item
          name="alcoholic"
          label="Alcoholic Drink?"
          valuePropName="checked"
          initialValue={1}
          getValueFromEvent={(checked) => (checked ? 1 : 0)}
        >
          <Switch defaultChecked />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default AlcoholicDrinkToggle;
