import { UserOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React from "react";

const { Option } = Select;

const UnitsDropdownForm = ({ value, onChange, unitsList }) => {
  let items = [];
  if (unitsList) {
    // TODO: If paginated check for && unitsList.content
    items = unitsList.map((item) => ({
      // TODO: If paginated this becomes unitsList.content.map
      label: item.name,
      key: item.id,
      icon: <UserOutlined />,
    }));
  }

  const handleUnitChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      showSearch
      value={value}
      onChange={handleUnitChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {items.map((item) => (
        <Option key={item.key} value={item.key}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

export default UnitsDropdownForm;
