import React from "react";
import DrinksResultsPaginated from "../results/DrinksResultsPaginated";
import { Link } from "react-router-dom";
import { Button } from "antd";

function DrinksPage() {
  return (
    <div>
      {(sessionStorage.getItem("userRole") === "ADMIN" ||
        sessionStorage.getItem("userRole") === "SUPER_ADMIN") && (
        <Link to="/drinks/create">
          <Button type="primary">Add New Drink</Button>
        </Link>
      )}
      <h1>Drinks</h1>
      <p>A list of all the tasty drinks in the database.</p>
      <DrinksResultsPaginated />
    </div>
  );
}

export default DrinksPage;
