import React, { useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";

const Logout = () => {
  const tempEmail = sessionStorage.getItem("userEmail");
  const { setJwt } = useContext(AuthContext);

  useEffect(() => {
    sessionStorage.removeItem("jwt");
    setJwt(null);
  }, []);

  return (
    <div>
      <h2>{`${tempEmail} Logged out`}</h2>
    </div>
  );
};

export default Logout;
