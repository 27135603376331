import React from "react";
import Logout from "../components/LogoutComponent";

const LogoutPage = () => {
  return (
    <div>
      <Logout />
    </div>
  );
};

export default LogoutPage;
