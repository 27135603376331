class DrinkDetailsQuery {
  constructor() {
    this.token = sessionStorage.getItem("jwt");
    this.baseURL = "http://localhost:8080/api";
  }

  async getData(id) {
    return fetch(`${this.baseURL}/drinks/${id}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    })
      .catch((error) => {
        console.error("Network error:", error);
        throw error;
      })
      .then((response) => {
        if (!response.ok) {
          console.log(`Unable to fetch drink ${id}: ${response.status}`);
        }
        return response.json();
      });
  }
}

export default DrinkDetailsQuery;
