import React from "react";
import CreateDrinkForm from "../forms/CreateDrinkForm";
import AccessDenied from "../components/AccessDenied";

function DrinksPageCreate() {
  if (!sessionStorage.getItem("jwt")) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {sessionStorage.getItem("userRole") === "ADMIN" ||
      sessionStorage.getItem("userRole") === "SUPER_ADMIN" ? (
        <CreateDrinkForm />
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default DrinksPageCreate;
