import React from "react";

function AboutPage() {
  return (
    <div>
      <h1>About</h1>
      <p>
        Dry. Neat. Dirty. is a web application for bartenders and mixologists.
        Please drink responsibly, and enjoy!
      </p>

      <h2>Glasses</h2>
      <p>Support for 49 standard drink glasses.</p>

      <h2>Inventory Manager</h2>
      <p>
        Our inventory manager supports 379 standard bar ingredients from lemon
        juice to yoghurt to vodka. Don&apos;t see something on the list?
        Customize it by adding your own unique ingredients. The inventory
        manager can be used to track & view supply levels or to narrow the
        recipe book to drinks you can make with the supplies on-hand.
      </p>

      <h2>Recipe Book</h2>
      <p>View our library of 350+ classic cocktails or create your own.</p>

      <h2>Unit Handling</h2>
      <p>
        Recipe units are displayed in fluid ounces. You can change them
        on-the-fly to any other compatible unit.{" "}
      </p>
    </div>
  );
}

export default AboutPage;
