import { Card } from "antd";

const Unit = ({ id, unitCategoryId, name, description }) => {
  return (
    <Card
      title={name}
      bordered={false}
      className="ingredient"
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        padding: "0px",
        border: "2px solid #d1dedeff",
        borderRadius: "7px",
      }}
      styles={{
        header: {
          backgroundColor: "#d1dedeff",
          borderRadius: "0px",
          fontfamily: "Cormorant Garamond",
          color: "#192a51ff",
          fontSize: "22px",
        },
      }}
    >
      <ul>
        <li>ID: {id}</li>
        <li>Unit Category ID: {unitCategoryId}</li>
        <li>Description: {description}</li>
      </ul>
    </Card>
  );
};

export default Unit;
