import React, { useState, useEffect } from "react";
import UnitsQuery from "../queries/UnitsQuery";
import UnitsResults from "../results/UnitsResults";

function UnitsPage() {
  const useFetchData = (QueryClass) => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const queryInstance = new QueryClass();
      queryInstance
        .getData()
        .then((data) => setData(data))
        .catch((error) => console.error(error));
    }, [QueryClass]);

    return data;
  };

  const unitsList = useFetchData(UnitsQuery);

  return (
    <div>
      <h1>Units</h1>
      <p>This is the units page.</p>
      <UnitsResults unitsList={unitsList} />
    </div>
  );
}

export default UnitsPage;
