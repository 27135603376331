import React from "react";
import { InputNumber } from "antd";

const QuantityForm = ({ value = 0, onChange }) => {
  const handleQuantityChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <InputNumber
      min={0}
      step={0.1}
      value={value}
      onChange={handleQuantityChange}
      placeholder="Enter a quantity"
      style={{ width: "100%" }}
    />
  );
};

export default QuantityForm;
