import { useParams, Link } from "react-router-dom";
import React, { useState } from "react";
import { Button } from "antd";

function DrinkDetailsDeletePage() {
  const { id } = useParams();
  const [statusMessage, setStatusMessage] = useState("");
  const [showButtons, setShowButtons] = useState(true);

  const deleteDrink = async () => {
    try {
      const jwt = sessionStorage.getItem("jwt");

      const response = await fetch(`http://localhost:8080/api/drinks/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        setStatusMessage(`Failed to DELETE drink ${id}`);
        setShowButtons(false);
        throw new Error("Failed to delete drink");
      }

      console.log("Drink deleted successfully");
      setShowButtons(false);
      setStatusMessage(`Successfully deleted drink ${id}`);
    } catch (error) {
      setShowButtons(false);
      console.error("Error:", error);
      setStatusMessage("" + error);
    }
  };

  return (
    <div>
      {(sessionStorage.getItem("userRole") === "ADMIN" ||
        sessionStorage.getItem("userRole") === "SUPER_ADMIN") && (
        <div>
          {showButtons && (
            <>
              <h2>Delete Drink {id}?</h2>
              <div>
                <Link to={`/drinks/edit/${id}`}>
                  <Button style={{ marginRight: "10px" }}>Cancel</Button>
                </Link>
                <Button type="primary" danger onClick={deleteDrink}>
                  YES
                </Button>
              </div>
            </>
          )}
          {!showButtons && (
            <div>
              <Link to={`/drinks`}>
                <Button type="primary" ghost>
                  Back
                </Button>
              </Link>
              <h3>{statusMessage}</h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DrinkDetailsDeletePage;
