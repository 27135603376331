import React from "react";

function AccessDenied() {
  const email = sessionStorage.getItem("email");
  const role = sessionStorage.getItem("role");

  return (
    <div>
      <h2>Access is denied for &quot;{email}&quot;</h2>
      <h3>
        Role <b>&quot;{role}&quot;</b> does not have permission to access this
        resource
      </h3>
    </div>
  );
}

export default AccessDenied;
