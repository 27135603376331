import React, { useRef, useEffect } from "react";

function ApplicationFooter() {
  const footerRef = useRef(null);
  let footerHeightPx;

  useEffect(() => {
    if (footerRef.current) {
      const footerHeight = footerRef.current.offsetHeight;
      footerHeightPx = `${footerHeight}px`;
      document.documentElement.style.setProperty(
        "--footer-height",
        footerHeightPx
      );
    }
  }, []);

  const style = {
    fontFamily: "'Cormorant Garamond', serif",
    color: "#d1dedeff",
    backgroundColor: "#1d201fff",
    padding: "20px",
    margin: "0",
    textAlign: "center",
  };

  return (
    <footer style={style} ref={footerRef}>
      Copyright 2024 Adam Goss
    </footer>
  );
}

export default ApplicationFooter;
