import React from "react";
import { useLocation } from "react-router-dom";

function DrinksPageEditFailure() {
  const location = useLocation();
  const data = location.state.data;
  console.log(data);
  return (
    <div>
      <h2>Failure</h2>
      <h1>{data.name}</h1>
      <p>Was not udpated.</p>
      <h2>Error:</h2>
      <p>{data && data.status ? data.status : ""}</p>
      <p>{data && data.detail ? data.detail : ""}</p>
    </div>
  );
}

export default DrinksPageEditFailure;
