import React, { useEffect, useRef } from "react";
import { Form, Button, Row, Col, Input, Switch } from "antd";
import QuantityForm from "./QuantityForm";
import UnitsDropdownForm from "./UnitsDropdownForm";
import IngredientsDropdownForm from "./IngredientsDropdownForm";
import { MinusCircleOutlined } from "@ant-design/icons";

const AddIngredientsForm = ({ ingredientsList, unitsList }) => {
  const addRef = useRef(null);

  useEffect(() => {
    if (addRef.current) {
      addRef.current();
    }
  }, []);

  return (
    <Form.List name="drinkIngredients">
      {(fields, { add, remove }) => {
        // Store the add function in the ref
        addRef.current = fields.length === 0 ? add : null;

        return (
          <>
            {fields.map((field, index) => (
              <Row key={field.key} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={1}>
                  <div>{index + 1}</div>
                  <Form.Item
                    name={[field.name, "ingredientOrder"]}
                    initialValue={index + 1}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    {...field}
                    name={[field.name, "quantity"]}
                    rules={[
                      { required: true, message: "Please input a quantity!" },
                    ]}
                  >
                    <QuantityForm />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    {...field}
                    name={[field.name, "unitId"]}
                    rules={[
                      { required: true, message: "Please select a unit!" },
                    ]}
                  >
                    <UnitsDropdownForm unitsList={unitsList} />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    {...field}
                    name={[field.name, "ingredientId"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select an ingredient!",
                      },
                    ]}
                    style={{ flex: 1 }}
                  >
                    <IngredientsDropdownForm
                      ingredientsList={ingredientsList}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "ingredientOptional"]}
                      label="Optional"
                      valuePropName="checked"
                      getValueFromEvent={(checked) => (checked ? 1 : 0)}
                    >
                      <Switch />
                    </Form.Item>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(field.name)}
                      danger
                      style={{ marginLeft: 16 }}
                    />
                  </div>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block>
                Add Another Ingredient
              </Button>
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

export default AddIngredientsForm;
