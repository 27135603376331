import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import DrinkDetailsQuery from "../queries/DrinkDetailsQuery";
import Drink from "../entities/Drink";
import { Button } from "antd";

function DrinkDetailsPage() {
  const { id } = useParams();
  const [drink, setDrink] = useState(null);

  useEffect(() => {
    const fetchDrink = async () => {
      const drinkDetailsQuery = new DrinkDetailsQuery();
      const data = await drinkDetailsQuery.getData(id);
      setDrink(data);
    };

    fetchDrink();
  }, [id]);

  return (
    <div>
      <div className="leftPadding20">
        {!drink || !drink.id ? (
          <div>
            <Link to={`/drinks`}>
              <Button type="primary" ghost>
                Back
              </Button>
            </Link>
            <h1>No Drink Found</h1>
          </div>
        ) : (
          <div>
            {drink &&
              drink.id &&
              (sessionStorage.getItem("userRole") === "ADMIN" ||
                sessionStorage.getItem("userRole") === "SUPER_ADMIN") && (
                <div>
                  <Link to={`/drinks`}>
                    <Button
                      type="primary"
                      ghost
                      style={{ marginRight: "10px" }}
                    >
                      Back
                    </Button>
                  </Link>
                  <Link to={`/drinks/edit/${id}`}>
                    <Button type="primary" style={{ marginRight: "10px" }}>
                      Edit
                    </Button>
                  </Link>
                </div>
              )}
            <Drink
              key={drink.id}
              id={drink.id}
              name={drink.name}
              createdByUserId={drink.createdByUserId}
              drinkCategoryId={drink.drinkCategoryId}
              glassId={drink.glassId}
              alcoholic={drink.alcoholic}
              description={drink.description}
              drinkIngredients={drink.drinkIngredients}
              instructions={drink.instructions}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DrinkDetailsPage;
