import React, { useRef, useEffect } from "react";

function ApplicationHeader() {
  const headerRef = useRef(null);

  useEffect(() => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    }
  }, []);

  const style = {
    fontFamily: '"Gravitas One", cursive',
    color: "#e84855ff",
    fontSize: "45pt",
    textAlign: "center",
    margin: "0px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "60px",
    paddingBottom: "60px",
  };

  return (
    <h1 style={style} ref={headerRef}>
      Dry. Neat. Dirty.
    </h1>
  );
}

export default ApplicationHeader;
