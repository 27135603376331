import React from "react";

const LoginPageSuccess = () => {
  return (
    <div>
      {sessionStorage.getItem("jwt") ? (
        <>
          <h2>
            &quot;{sessionStorage.getItem("userEmail")}&quot; Logged in
            successfully.
          </h2>
          <h3>With role &quot;{sessionStorage.getItem("userRole")}&quot;</h3>
        </>
      ) : (
        <h3>Not Authenticated!</h3>
      )}
    </div>
  );
};

export default LoginPageSuccess;
