import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "antd";

function DrinksPageCreateSuccess() {
  const location = useLocation();
  const data = location.state.data;
  return (
    <div>
      {(sessionStorage.getItem("userRole") === "ADMIN" ||
        sessionStorage.getItem("userRole") === "SUPER_ADMIN") && (
        <Link to="/drinks/create">
          <Button type="primary">Add Another Drink</Button>
        </Link>
      )}
      <h2>Success</h2>
      <h1>{data.name}</h1>
      <p>Was added to the database.</p>
    </div>
  );
}

export default DrinksPageCreateSuccess;
