import { Card } from "antd";

const Ingredient = ({
  id,
  name,
  ingredientNameSingular,
  ingredientNamePlural,
  ingredientCategoryId,
  description,
  ingredientSubcategoryId,
}) => {
  return (
    <Card
      title={name}
      bordered={false}
      className="ingredient"
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        padding: "0px",
        border: "2px solid #d1dedeff",
        borderRadius: "7px",
      }}
      styles={{
        header: {
          backgroundColor: "#d1dedeff",
          borderRadius: "0px",
          fontfamily: "Cormorant Garamond",
          color: "#192a51ff",
          fontSize: "22px",
        },
      }}
    >
      <ul>
        <li>ID: {id}</li>
        <li>Ingredient Category ID: {ingredientCategoryId}</li>
        <li>Ingredient Name Singular: {ingredientNameSingular}</li>
        <li>Ingredient Name Plural: {ingredientNamePlural}</li>
        <li>Description: {description}</li>
        <li>Ingredient Subcategory ID: {ingredientSubcategoryId}</li>
      </ul>
    </Card>
  );
};

export default Ingredient;
