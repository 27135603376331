import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import DrinksQuery from "../queries/DrinksQuery";
import Drink from "../entities/Drink";

function DrinksResultsPaginated() {
  const [drinksList, setDrinksList] = useState({ content: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const queryInstance = new DrinksQuery();
    queryInstance
      .getData(currentPage - 1, pageSize)
      .then((data) => setDrinksList(data))
      .catch((error) => console.error(error));
  }, [currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={drinksList.totalElements}
        onChange={handlePageChange}
        showSizeChanger={true}
      />
      <div className="leftPadding20">
        {!drinksList.content.length ? (
          <h1>No Drinks Found</h1>
        ) : (
          drinksList.content.map((drink) => (
            <Drink
              key={drink.id}
              id={drink.id}
              name={drink.name}
              createdByUserId={drink.createdByUserId}
              drinkCategoryId={drink.drinkCategoryId}
              glassId={drink.glassId}
              alcoholic={drink.alcoholic}
              description={drink.description}
              drinkIngredients={drink.drinkIngredients}
              instructions={drink.instructions}
            />
          ))
        )}
      </div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={drinksList.totalElements}
        onChange={handlePageChange}
        showSizeChanger={true}
      />
    </div>
  );
}

export default DrinksResultsPaginated;
