import React, { useRef, useEffect, useContext } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";

function LoginBar() {
  const headerRef = useRef(null);
  const { jwt, setJwt } = useContext(AuthContext);

  useEffect(() => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setJwt(sessionStorage.getItem("jwt"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const style = {
    fontFamily: '"Julius Sans One", sans-serif',
    color: "#e84855ff",
    backgroundColor: "#192a51ff",
    textAlign: "right",
    textSize: "12px",
    margin: "0px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  return (
    <div style={style} ref={headerRef}>
      {jwt ? (
        <Button
          style={{
            backgroundColor: "#1d201fff",
            borderColor: "#592941ff",
            color: "#fff",
          }}
          onClick={() => {
            sessionStorage.removeItem("jwt");
            setJwt(null);
          }}
        >
          <Link className="loginBarLink" to="/logout">
            Log Out
          </Link>
        </Button>
      ) : (
        <Button
          style={{
            backgroundColor: "#1d201fff",
            borderColor: "#592941ff",
            color: "#fff",
          }}
        >
          <Link className="loginBarLink" to="/login">
            Log In
          </Link>
        </Button>
      )}
    </div>
  );
}

export default LoginBar;
