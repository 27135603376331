import React from "react";
import GlassesResultsPaginated from "../results/GlassesResultsPaginated";

function GlassesPage() {
  return (
    <div>
      <h1>Glasses</h1>
      <p>A list of all the fancy glasses in the database.</p>
      <GlassesResultsPaginated />
    </div>
  );
}

export default GlassesPage;
