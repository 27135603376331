import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "antd";

function DrinksPageCreateFailure() {
  const location = useLocation();
  const data = location.state.data;
  return (
    <div>
      {(sessionStorage.getItem("userRole") === "ADMIN" ||
        sessionStorage.getItem("userRole") === "SUPER_ADMIN") && (
        <Link to="/drinks/create">
          <Button type="primary">Add Another Drink</Button>
        </Link>
      )}
      <h2>Failure</h2>
      <h1>{data.name}</h1>
      <p>Was not added to the database.</p>
      <h2>Error:</h2>
      <p>
        {data && data.error && data.error.message ? data.error.message : ""}
      </p>
    </div>
  );
}

export default DrinksPageCreateFailure;
