import { Card } from "antd";

const Glass = ({ id, name, description, image }) => {
  return (
    <Card
      title={name}
      bordered={false}
      className="glass"
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        padding: "0px",
        border: "2px solid #d1dedeff",
        borderRadius: "7px",
      }}
      styles={{
        header: {
          backgroundColor: "#d1dedeff",
          borderRadius: "0px",
          fontfamily: "Cormorant Garamond",
          color: "#192a51ff",
          fontSize: "22px",
        },
      }}
    >
      <ul>
        <li>ID: {id}</li>
        <li>Description: {description}</li>
        <li>Image: {image}</li>
      </ul>
    </Card>
  );
};

export default Glass;
