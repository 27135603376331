import { StrictMode } from "react";
import AppRouter from "./AppRouter";
import { ConfigProvider } from "antd";
import theme from "./theme/theme-dryNeatDirty";

const App = () => {
  return (
    <StrictMode>
      <ConfigProvider theme={theme}>
        <AppRouter />
      </ConfigProvider>
    </StrictMode>
  );
};

export default App;
