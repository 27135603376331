import React from "react";

function HomePage() {
  const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  };

  return (
    <div style={style}>
      <br />
      <br />
      <br />
      <img
        src={require("../images/DryNeatDirty-Logo-2023-003-black.svg")}
        alt="Dry. Neat. Dirty. Logo."
        height="300px"
      />
      <br />
      <br />
      <br />
      <h1>Recipes for drinks,</h1>
      <h1>from the ingredients you have.</h1>
    </div>
  );
}

export default HomePage;
