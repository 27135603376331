import React from "react";
import { Form, Input } from "antd";

function DescriptionForm() {
  return (
    <Form.Item
      label="Description"
      name="description"
      rules={[{ required: true, message: "Please input a drink description!" }]}
    >
      <Input />
    </Form.Item>
  );
}

export default DescriptionForm;
