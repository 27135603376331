import React from "react";

const LoginPageFailure = () => {
  return (
    <div>
      <h2>Log in failure.</h2>
      <h3>Failed to authenticate user.</h3>
    </div>
  );
};

export default LoginPageFailure;
