import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import GlassesQuery from "../queries/GlassesQuery";
import Glass from "../entities/Glass";

function GlassesResultsPaginated() {
  const [glassesList, setGlassesList] = useState({ content: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const queryInstance = new GlassesQuery();
    queryInstance
      .getData(currentPage - 1, pageSize)
      .then((data) => setGlassesList(data))
      .catch((error) => console.error(error));
  }, [currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={glassesList.totalElements}
        onChange={handlePageChange}
        showSizeChanger={true}
      />
      <div className="leftPadding20">
        {!glassesList.content.length ? (
          <h1>No Glasses Found</h1>
        ) : (
          glassesList.content.map((glass) => (
            <Glass
              key={glass.id}
              id={glass.id}
              name={glass.name}
              description={glass.description}
              image={glass.image}
            />
          ))
        )}
      </div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={glassesList.totalElements}
        onChange={handlePageChange}
        showSizeChanger={true}
      />
    </div>
  );
}

export default GlassesResultsPaginated;
