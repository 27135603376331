import React from "react";

import IngredientsResultsPaginated from "../results/IngredientsResultsPaginated";

function IngredientsPage() {
  return (
    <div>
      <h1>Ingredients</h1>
      <p>A list of all the yummy ingredients in the database.</p>
      <IngredientsResultsPaginated />
    </div>
  );
}

export default IngredientsPage;
