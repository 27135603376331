import { UserOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React from "react";

const { Option } = Select;

const IngredientsDropdownForm = ({ value, onChange, ingredientsList }) => {
  let items = [];
  if (ingredientsList && ingredientsList.content) {
    items = ingredientsList.content.map((item) => ({
      label: item.name,
      key: item.id,
      icon: <UserOutlined />,
    }));
  }

  const handleIngredientChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      showSearch
      value={value}
      onChange={handleIngredientChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {items.map((item) => (
        <Option key={item.key} value={item.key}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

export default IngredientsDropdownForm;
